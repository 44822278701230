var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "partner" }, [
      _c("div", { staticClass: "img-wrapper" }, [
        _c("img", { attrs: { src: require("./img/1.png") } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "img-wrapper" }, [
        _c("img", { attrs: { src: require("./img/2.png") } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "img-wrapper" }, [
        _c("img", { attrs: { src: require("./img/3.png") } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "img-wrapper" }, [
        _c("img", { attrs: { src: require("./img/4.png") } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "img-wrapper" }, [
        _c("img", { attrs: { src: require("./img/5.png") } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "img-wrapper" }, [
        _c("img", { attrs: { src: require("./img/6.png") } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "img-wrapper" }, [
        _c("img", { attrs: { src: require("./img/11.png") } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "img-wrapper" }, [
        _c("img", { attrs: { src: require("./img/12.png") } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }