var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "index" },
    [
      _c("yqg-header", { on: { "show-contract-us": _vm.showContractUs } }),
      _vm._v(" "),
      _c("banner"),
      _vm._v(" "),
      _c("idea"),
      _vm._v(" "),
      _c("service"),
      _vm._v(" "),
      _c("feature"),
      _vm._v(" "),
      _c("partner"),
      _vm._v(" "),
      _c("yqg-footer", { on: { "show-contract-us": _vm.showContractUs } }),
      _vm._v(" "),
      _c("contract-us", { ref: "contractUs" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }