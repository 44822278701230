var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "feature", attrs: { id: "portfolio" } }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "portfolio-overlay" }, [
          _c("img", {
            staticClass: "img-port",
            attrs: { src: require("./img/p2.jpg") },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "div-overlay" }, [
            _c("div", { staticClass: "port-1 service" }, [
              _vm._v("\n                    快速便捷提需求\n                "),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "portfolio-overlay" }, [
          _c("img", {
            staticClass: "img-port",
            attrs: { src: require("./img/p1.jpg") },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "div-overlay" }, [
            _c("div", { staticClass: "port-1 service" }, [
              _vm._v("\n                    足不出户拿项目\n                "),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "portfolio-overlay" }, [
          _c("img", {
            staticClass: "img-port",
            attrs: { src: require("./img/p4.jpg") },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "div-overlay" }, [
            _c("div", { staticClass: "port-1 service" }, [
              _vm._v("\n                    定制个性化网站\n                "),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "portfolio-overlay" }, [
          _c("img", {
            staticClass: "img-port",
            attrs: { src: require("./img/p8.jpg") },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "div-overlay" }, [
            _c("div", { staticClass: "port-1 service" }, [
              _vm._v("\n                    电子商务一站式\n                "),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }