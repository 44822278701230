<!-- @author hongweihu -->
<!-- @email hongweihu@fintopia.tech -->
<!-- @date 2022-06-08 15:10:11.063 -->
<!-- @desc generated by yqg-cli@5.3.0 -->

<template>
    <div class="partner">
        <div class="img-wrapper">
            <img src="./img/1.png">
        </div>
        <div class="img-wrapper">
            <img src="./img/2.png">
        </div>
        <div class="img-wrapper">
            <img src="./img/3.png">
        </div>
        <div class="img-wrapper">
            <img src="./img/4.png">
        </div>
        <div class="img-wrapper">
            <img src="./img/5.png">
        </div>
        <div class="img-wrapper">
            <img src="./img/6.png">
        </div>
        <div class="img-wrapper">
            <img src="./img/11.png">
        </div>
        <div class="img-wrapper">
            <img src="./img/12.png">
        </div>
    </div>
</template>

<script type="text/babel">

    export default {
        name: 'Partner'
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $ultra-book-min: 760px;

    .partner {
        padding: 50px 0;
        background-color: #fff;

        .img-wrapper {
            display: inline-block;
            width: 24.5%;
            text-align: center;
        }

        img {
            width: 100px;
        }

        @media screen and (max-width: $ultra-book-min) {
            .img-wrapper {
                width: 49.5%;
            }
        }
    }

</style>
