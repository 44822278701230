<!-- @Author: hongweihu -->
<!-- @Date: 2022-06-09 15:08:52.896 -->
<!-- @Last Modified by: hongweihu -->
<!-- @Last Modified time: 2023-10-23 13:00:29 -->

<template>
    <div class="banner">
        <div class="bg" />
        <div class="text-first">
            专业 &nbsp;• &nbsp;简单 &nbsp;• &nbsp;可靠
        </div>
        <div class="feature-list">
            <div class="animation-wrapper">
                <div class="big-text sl hide">
                    快速简单发布需求
                </div>
                <div class="big-text sl hide">
                    精准高效获取客户
                </div>
                <div class="big-text sl hide">
                    品牌网站设计和电商开发
                </div>
            </div>
        </div>
        <div class="small-text">
            {{ companyInfo.companyName }}为中小企业提供有偿信息服务（提供付费查询项目详情和联系方式）、网站建设等全方位互联网解决方案。
        </div>
        <div class="button-group">
            <a
                class="button button-hero"
                href="#about"
            >了解详情</a>
        </div>
        <div class="arrow">
            <img src="./img/arrow.png">
        </div>
    </div>
</template>

<script type="text/babel">
    import {mapGetters} from 'vuex';

    import {G_TYPES} from 'moneycan/common/store/company-info';

    export default {
        name: 'Banner',

        computed: {
            ...mapGetters({
                companyInfo: G_TYPES.GET_COMPANY_INFO
            })
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $ultra-book-min: 760px;

    .banner {
        padding: 100px 0;
        background-image: url("./img/pp.jpg");
        background-size: cover;
        background-repeat: no-repeat;

        .text-first {
            padding-top: 20px;
            padding-bottom: 20px;
            font-family: "Sorts Mill Goudy", sans-serif;
            color: #c7c7c7;
            font-size: 16px;
            font-style: italic;
            font-weight: 600;
            text-align: center;
            letter-spacing: 0;
            text-transform: none;
        }

        .feature-list {
            height: 92.8px;
            overflow: hidden;

            .animation-wrapper {
                animation: word 6s linear infinite;

                .big-text {
                    text-align: center;
                    font-size: 58px;
                    color: #fff;
                    font-weight: 600;
                }
            }

            @keyframes word {
                0% {
                    transform: translateY(0);
                }

                20% {
                    transform: translateY(0);
                }

                40% {
                    transform: translateY(-92.8px * 1);
                }

                60% {
                    transform: translateY(-92.8px * 1);
                }

                80% {
                    transform: translateY(-92.8px * 2);
                }

                100% {
                    transform: translateY(-92.8px * 2);
                }

                0% {
                    transform: translateY(0);
                }
            }

            @media screen and (max-width: $ultra-book-min) {
                height: 32px;

                .animation-wrapper {
                    animation: word2 5s linear infinite;

                    .big-text {
                        text-align: center;
                        font-size: 20px;
                        color: #fff;
                        font-weight: 600;
                    }
                }

                @keyframes word2 {
                    0% {
                        transform: translateY(0);
                    }

                    20% {
                        transform: translateY(0);
                    }

                    40% {
                        transform: translateY(-32px * 1);
                    }

                    60% {
                        transform: translateY(-32px * 1);
                    }

                    80% {
                        transform: translateY(-32px * 2);
                    }

                    100% {
                        transform: translateY(-32px * 2);
                    }

                    0% {
                        transform: translateY(0);
                    }
                }
            }
        }

        .small-text {
            padding: 20px;
            font-family: Raleway, sans-serif;
            color: #c7c7c7;
            font-size: 16px;
            line-height: 36px;
            text-align: center;
        }

        .button-group {
            margin-top: 27px;
            text-align: center;

            .button {
                display: inline-block;
                padding: 10px 20px;
                border: 1px solid #dbdbdb;
                border-radius: 5px;
                background-color: transparent;
                opacity: 75%;
                transition: opacity 500ms ease;
                color: #9c9c9c;
                font-size: 13px;
                line-height: 32px;
                font-weight: 600;
                text-align: center;
                letter-spacing: 1px;
                text-decoration: none;
                text-transform: uppercase;
            }
        }

        .arrow {
            padding: 20px 0;
            text-align: center;

            img {
                width: 20px;
                animation: to-bottom 1s;
                animation-iteration-count: infinite;
            }

            @keyframes to-bottom {
                from {
                    transform: translateX(0) translateY(-10px);
                }

                to {
                    transform: translateX(0) translateY(0);
                }
            }
        }
    }

</style>
