<!-- @author hongweihu -->
<!-- @email hongweihu@fintopia.tech -->
<!-- @date 2022-06-09 13:32:36.807 -->
<!-- @desc generated by yqg-cli@5.3.0 -->

<template>
    <div
        id="about"
        class="idea"
    >
        <div class="container">
            <div class="title">
                <h4>WHO WE ARE.</h4>
                <h1>我们的理念</h1>
                <div>
                    <div class="line" />
                    <div class="circle-stroke" />
                </div>
            </div>
            <div class="idea-list">
                <div class="idea-detail">
                    <img src="./img/2.jpg">
                    <span class="title">全方位 高覆盖</span>
                    <p class="desc">
                        覆盖了各个行业的不同需求，不同行业都可以快速发布项目找到适合的服务提供商。
                    </p>
                </div>
                <div class="idea-detail">
                    <img src="./img/1.jpg">
                    <span class="title">简单 高效 可靠</span>
                    <p class="desc">
                        仅需简单操作即可达成合作意向，免去传统方式大量无谓的线下工作。
                    </p>
                </div>
                <div class="idea-detail">
                    <img src="./img/3.jpg">
                    <span class="title">不仅仅是网站</span>
                    <p class="desc">
                        为客户建设的网站要能够发挥网站的作用，网站不仅拥有华丽的设计，而且还具有极佳营销性。
                    </p>
                </div>
            </div>
            <button class="button">
                产品功能
            </button>
        </div>
    </div>
</template>

<script type="text/babel">

    export default {
        name: 'Idea'
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $ultra-book-min: 760px;

    .idea {
        padding: 100px 0;
        background-color: #fff;

        .container {
            text-align: center;

            .title {
                h4 {
                    margin: 10px 0;
                    padding: 13px 0;
                    color: #b3b3b3;
                    font-size: 13px;
                    line-height: 24px;
                    text-align: center;
                    font-weight: 600;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                }

                h1 {
                    margin-top: 0;
                    margin-bottom: 50px;
                    color: #3b3b3b;
                    font-size: 38px;
                    line-height: 44px;
                    font-weight: 600;
                    text-align: center;
                }

                .line {
                    display: block;
                    width: 30%;
                    height: 1px;
                    margin-right: auto;
                    margin-left: auto;
                    background-color: #e1e1e1;
                }

                .circle-stroke {
                    position: relative;
                    bottom: 8px;
                    display: block;
                    width: 15px;
                    height: 15px;
                    margin-right: auto;
                    margin-left: auto;
                    border: 1px solid #d4d4d4;
                    border-radius: 100%;
                    background-color: #f2f2f2;
                }
            }

            .idea-list {
                display: flex;
                justify-content: space-between;
                margin: 70px 0 60px;
                width: 100%;

                .idea-detail {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 32%;

                    img {
                        margin-bottom: 25px;
                        width: 100%;
                    }

                    .title {
                        margin-top: 0;
                        margin-bottom: 15px;
                        color: #3b3b3b;
                        font-size: 20px;
                        line-height: 30px;
                        font-style: normal;
                        font-weight: 700;
                        text-align: left;
                    }

                    .desc {
                        margin-top: 0;
                        margin-bottom: 5px;
                        padding-right: 0;
                        padding-left: 0;
                        font-family: Raleway, sans-serif;
                        color: #919191;
                        font-size: 13px;
                        line-height: 26px;
                        font-weight: 400;
                        text-align: left;
                    }
                }

                @media screen and (max-width: $ultra-book-min) {
                    flex-direction: column;

                    .idea-detail {
                        width: 100%;
                    }
                }
            }

            .button {
                display: inline-block;
                padding: 10px 20px;
                border: 1px solid #dbdbdb;
                border-radius: 5px;
                background-color: transparent;
                opacity: 75%;
                transition: opacity 500ms ease;
                color: #9c9c9c;
                font-size: 13px;
                line-height: 32px;
                font-weight: 600;
                text-align: center;
            }
        }
    }

</style>
