var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "service", attrs: { id: "services" } }, [
    _c("div", { staticClass: "container" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "service-wrapper" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "column first" }, [
          _c("div", { staticClass: "service-detail first" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("span", { staticClass: "title" }, [_vm._v("项目发布")]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v(
                "\n                        客户可通过" +
                  _vm._s(_vm.companyInfo.shortName) +
                  "发布自己的需求，如：问题咨询、招聘求职、物品采购等。\n                    "
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(3),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "column" }, [
          _c("div", { staticClass: "service-detail first" }, [
            _vm._m(4),
            _vm._v(" "),
            _c("span", { staticClass: "title" }, [_vm._v("项目对接")]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v(
                "\n                        服务提供方在" +
                  _vm._s(_vm.companyInfo.shortName) +
                  "注册通过验证成为用户后可按照分类筛选有潜在意愿的需求方。\n                    "
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(5),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("h4", [_vm._v("WE MAKE IT EASY.")]),
      _vm._v(" "),
      _c("h1", [_vm._v("我们的服务")]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "line" }),
        _vm._v(" "),
        _c("div", { staticClass: "circle-stroke" }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img-wrapper" }, [
      _c("img", { attrs: { src: require("./img/iphone6_lingyue.jpg") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "circle-service" }, [
      _c("img", { attrs: { src: require("./img/ico1.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "service-detail last" }, [
      _c("div", { staticClass: "circle-service" }, [
        _c("img", { attrs: { src: require("./img/ico2.png") } }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [_vm._v("网站设计")]),
      _vm._v(" "),
      _c("p", { staticClass: "desc" }, [
        _vm._v(
          "\n                        优秀的设计团队，为您提供符合您业务需要并且美观的公司网页解决方案。\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "circle-service" }, [
      _c("img", { attrs: { src: require("./img/ico5.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "service-detail" }, [
      _c("div", { staticClass: "circle-service" }, [
        _c("img", { attrs: { src: require("./img/ico4.png") } }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [_vm._v("电商开发")]),
      _vm._v(" "),
      _c("p", { staticClass: "desc" }, [
        _vm._v(
          "\n                        依托丰富的电子商务运营经验，可以提供一揽子电子商务解决方案。\n                    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }