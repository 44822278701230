var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idea", attrs: { id: "about" } }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "title" }, [
          _c("h4", [_vm._v("WHO WE ARE.")]),
          _vm._v(" "),
          _c("h1", [_vm._v("我们的理念")]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "line" }),
            _vm._v(" "),
            _c("div", { staticClass: "circle-stroke" }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "idea-list" }, [
          _c("div", { staticClass: "idea-detail" }, [
            _c("img", { attrs: { src: require("./img/2.jpg") } }),
            _vm._v(" "),
            _c("span", { staticClass: "title" }, [_vm._v("全方位 高覆盖")]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v(
                "\n                    覆盖了各个行业的不同需求，不同行业都可以快速发布项目找到适合的服务提供商。\n                "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "idea-detail" }, [
            _c("img", { attrs: { src: require("./img/1.jpg") } }),
            _vm._v(" "),
            _c("span", { staticClass: "title" }, [_vm._v("简单 高效 可靠")]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v(
                "\n                    仅需简单操作即可达成合作意向，免去传统方式大量无谓的线下工作。\n                "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "idea-detail" }, [
            _c("img", { attrs: { src: require("./img/3.jpg") } }),
            _vm._v(" "),
            _c("span", { staticClass: "title" }, [_vm._v("不仅仅是网站")]),
            _vm._v(" "),
            _c("p", { staticClass: "desc" }, [
              _vm._v(
                "\n                    为客户建设的网站要能够发挥网站的作用，网站不仅拥有华丽的设计，而且还具有极佳营销性。\n                "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "button" }, [
          _vm._v("\n            产品功能\n        "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }