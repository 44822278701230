/**
 * @author hongweihu
 * @email hongweihu@yangqianguan.com
 * @date 2019-12-04 16:37:23.969
 * @desc generated by yqg-cli@1.0.4
 */
import ContractUs from 'moneycan/app/lingyue-tech/component/contact-us';
import YqgFooter from 'moneycan/app/lingyue-tech/component/yqg-footer';
import YqgHeader from 'moneycan/app/lingyue-tech/component/yqg-header';

import Banner from './component/banner';
import Feature from './component/feature';
import Idea from './component/idea';
import Partner from './component/partner';
import Service from './component/service';
import Team from './component/team';

export default {
    title: '科技平台',

    head() {
        return {
            meta: [
                {name: 'robots', content: 'noindex,nofollow,noarchive'},
                {name: 'baidu-site-verification', content: 'codeva-SfXQEOQXxe'}
            ]
        };
    },

    components: {
        YqgHeader,
        YqgFooter,
        Banner,
        Partner,
        Team,
        Feature,
        Service,
        Idea,
        ContractUs
    },

    methods: {
        showContractUs() {
            this.$refs.contractUs.changeShow(true);
        }
    }
};
