var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "team", attrs: { id: "team" } }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "title" }, [
          _c("h4", [_vm._v("Interesting facts about our specialists.")]),
          _vm._v(" "),
          _c("h1", [_vm._v("我们的团队")]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "line" }),
            _vm._v(" "),
            _c("div", { staticClass: "circle-stroke" }),
          ]),
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "team-wrapper" }, [
          _c("li", { staticClass: "team-member" }, [
            _c("img", { attrs: { src: require("./img/chairman.png") } }),
            _vm._v(" "),
            _c("div", { staticClass: "intro" }, [
              _c("span", { staticClass: "name" }, [_vm._v("周亚辉")]),
              _vm._v(" "),
              _c("span", { staticClass: "pos" }, [_vm._v("董事长")]),
              _vm._v(" "),
              _c("p", { staticClass: "desc" }, [
                _vm._v(
                  "\n                        国际著名的企业家和投资人，现任昆仑万维董事长。于2008年创立昆仑万维集团，\n                        并作为CEO带领公司于2015年上市[SZ:300418]。作为资深投资人，2015年以来在全球20多家企业中注资超过3亿美金，\n                        目前已收获三家估值在10亿美金以上的独角兽公司。周亚辉先生在互联网金融行业有着深入的布局，\n                        投资公司包括趣店（前趣分期），随手记，银客网等。\n                    "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "team-member" }, [
            _c("img", { attrs: { src: require("./img/ceo.png") } }),
            _vm._v(" "),
            _c("div", { staticClass: "intro" }, [
              _c("span", { staticClass: "name" }, [_vm._v("刘永延")]),
              _vm._v(" "),
              _c("span", { staticClass: "pos" }, [_vm._v("CEO")]),
              _vm._v(" "),
              _c("p", { staticClass: "desc" }, [
                _vm._v(
                  "\n                        前Facebook全球支付平台负责人，业内顶尖的互联网金融、支付、安全专家，\n                        率团队为Facebook广告、电子商务、个人支付等多条业务线的高速增长提供了坚实的保障，\n                        创造了系统年交易量100亿美金的辉煌历史。2007-2009年就职于Google，将Google购物搜索带出美国，\n                        走进中日韩。毕业于复旦大学。\n                    "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "team-member" }, [
            _c("img", { attrs: { src: require("./img/cto.png") } }),
            _vm._v(" "),
            _c("div", { staticClass: "intro" }, [
              _c("span", { staticClass: "name" }, [_vm._v("耿博")]),
              _vm._v(" "),
              _c("span", { staticClass: "pos" }, [_vm._v("CTO")]),
              _vm._v(" "),
              _c("p", { staticClass: "desc" }, [
                _vm._v(
                  "\n                        前微软美国资深架构师，负责Bing图像搜索的后台架构和大数据平台。\n                        作为全世界第二大的图像搜索平台的核心技术领袖，带领团队奠基并将该产品发展为十亿级用户量级的应用。\n                        北京大学博士，30余篇论文在世界顶级期刊发表，被引用近千次。\n                    "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "team-member" }, [
            _c("img", { attrs: { src: require("./img/asset.png") } }),
            _vm._v(" "),
            _c("div", { staticClass: "intro" }, [
              _c("span", { staticClass: "name" }, [_vm._v("严峻")]),
              _vm._v(" "),
              _c("span", { staticClass: "pos" }, [_vm._v("CMO")]),
              _vm._v(" "),
              _c("p", { staticClass: "desc" }, [
                _vm._v(
                  "\n                        昆仑万维市场VP，互联网及游戏行业多年市场营销经验，曾主导多款千万用户级互联网产品的全球营销工作，\n                        精通线上线下广告运作模式，是业内少有的具有数亿美金市场费用投放实操经验的行业专家。\n                    "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "team-member" }, [
            _c("img", { attrs: { src: require("./img/marketing.png") } }),
            _vm._v(" "),
            _c("div", { staticClass: "intro" }, [
              _c("span", { staticClass: "name" }, [_vm._v("贾旭东")]),
              _vm._v(" "),
              _c("span", { staticClass: "pos" }, [_vm._v("产品合伙人")]),
              _vm._v(" "),
              _c("p", { staticClass: "desc" }, [
                _vm._v(
                  "\n                        前聚美优品母婴业务负责人兼产品总监，先后在京东、新浪微博任职。仅用四个月时间造就中国最大跨境母婴电商，\n                        深度亲历近五年中国现象级互联网公司的黄金时代，是极少拥有运营、营销、产品综合经验的创业者。\n                    "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }