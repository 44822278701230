var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "banner" }, [
    _c("div", { staticClass: "bg" }),
    _vm._v(" "),
    _c("div", { staticClass: "text-first" }, [
      _vm._v("\n        专业  •  简单  •  可靠\n    "),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "small-text" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.companyInfo.companyName) +
          "为中小企业提供有偿信息服务（提供付费查询项目详情和联系方式）、网站建设等全方位互联网解决方案。\n    "
      ),
    ]),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "feature-list" }, [
      _c("div", { staticClass: "animation-wrapper" }, [
        _c("div", { staticClass: "big-text sl hide" }, [
          _vm._v("\n                快速简单发布需求\n            "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "big-text sl hide" }, [
          _vm._v("\n                精准高效获取客户\n            "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "big-text sl hide" }, [
          _vm._v("\n                品牌网站设计和电商开发\n            "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "button-group" }, [
      _c(
        "a",
        { staticClass: "button button-hero", attrs: { href: "#about" } },
        [_vm._v("了解详情")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "arrow" }, [
      _c("img", { attrs: { src: require("./img/arrow.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }