<!-- @author hongweihu -->
<!-- @email hongweihu@fintopia.tech -->
<!-- @date 2022-06-08 15:52:30.341 -->
<!-- @desc generated by yqg-cli@5.3.0 -->

<template>
    <div
        id="portfolio"
        class="feature"
    >
        <div class="row">
            <div class="portfolio-overlay">
                <img
                    class="img-port"
                    src="./img/p2.jpg"
                >
                <div class="div-overlay">
                    <div class="port-1 service">
                        快速便捷提需求
                    </div>
                </div>
            </div>
            <div class="portfolio-overlay">
                <img
                    class="img-port"
                    src="./img/p1.jpg"
                >
                <div class="div-overlay">
                    <div class="port-1 service">
                        足不出户拿项目
                    </div>
                </div>
            </div>
            <div class="portfolio-overlay">
                <img
                    class="img-port"
                    src="./img/p4.jpg"
                >
                <div class="div-overlay">
                    <div class="port-1 service">
                        定制个性化网站
                    </div>
                </div>
            </div>
            <div class="portfolio-overlay">
                <img
                    class="img-port"
                    src="./img/p8.jpg"
                >
                <div class="div-overlay">
                    <div class="port-1 service">
                        电子商务一站式
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">

    export default {
        name: 'Feature'
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $ultra-book-min: 760px;

    .feature {
        .row {
            width: 100vw;
            display: flex;

            .portfolio-overlay {
                position: relative;
                width: 25vw;

                .img-port {
                    position: relative;
                    width: 100%;
                    height: auto;
                    transition: transform 500ms ease 0s;
                    z-index: 2;
                }

                .div-overlay {
                    position: absolute;
                    left: 0;
                    bottom: 6px;
                    z-index: 1;
                    margin-top: -7px;
                    padding: 30px 0 10px;
                    width: 100%;
                    color: rgba(255, 255, 255, 0%);
                    font-weight: 600;
                    font-size: 15px;
                    text-align: center;
                    background-color: black;
                    transition: color 500ms ease 0s;
                }

                &:hover {
                    .div-overlay {
                        color: rgba(255, 255, 255, 100%);
                    }

                    .img-port {
                        transform: translateX(0) translateY(-64px);
                    }
                }
            }

            @media screen and (max-width: $ultra-book-min) {
                flex-wrap: wrap;

                .portfolio-overlay {
                    margin-top: -6px;
                    width: 100%;
                }
            }
        }
    }

</style>
