<!-- @author hongweihu -->
<!-- @email hongweihu@fintopia.tech -->
<!-- @date 2022-06-08 15:19:52.549 -->
<!-- @desc generated by yqg-cli@5.3.0 -->

<template>
    <div
        id="team"
        class="team"
    >
        <div class="container">
            <div class="title">
                <h4>Interesting facts about our specialists.</h4>
                <h1>我们的团队</h1>
                <div>
                    <div class="line" />
                    <div class="circle-stroke" />
                </div>
            </div>
            <ul class="team-wrapper">
                <li class="team-member">
                    <img src="./img/chairman.png">
                    <div class="intro">
                        <span class="name">周亚辉</span>
                        <span class="pos">董事长</span>
                        <p class="desc">
                            国际著名的企业家和投资人，现任昆仑万维董事长。于2008年创立昆仑万维集团，
                            并作为CEO带领公司于2015年上市[SZ:300418]。作为资深投资人，2015年以来在全球20多家企业中注资超过3亿美金，
                            目前已收获三家估值在10亿美金以上的独角兽公司。周亚辉先生在互联网金融行业有着深入的布局，
                            投资公司包括趣店（前趣分期），随手记，银客网等。
                        </p>
                    </div>
                </li>
                <li class="team-member">
                    <img src="./img/ceo.png">
                    <div class="intro">
                        <span class="name">刘永延</span>
                        <span class="pos">CEO</span>
                        <p class="desc">
                            前Facebook全球支付平台负责人，业内顶尖的互联网金融、支付、安全专家，
                            率团队为Facebook广告、电子商务、个人支付等多条业务线的高速增长提供了坚实的保障，
                            创造了系统年交易量100亿美金的辉煌历史。2007-2009年就职于Google，将Google购物搜索带出美国，
                            走进中日韩。毕业于复旦大学。
                        </p>
                    </div>
                </li>
                <li class="team-member">
                    <img src="./img/cto.png">
                    <div class="intro">
                        <span class="name">耿博</span>
                        <span class="pos">CTO</span>
                        <p class="desc">
                            前微软美国资深架构师，负责Bing图像搜索的后台架构和大数据平台。
                            作为全世界第二大的图像搜索平台的核心技术领袖，带领团队奠基并将该产品发展为十亿级用户量级的应用。
                            北京大学博士，30余篇论文在世界顶级期刊发表，被引用近千次。
                        </p>
                    </div>
                </li>
                <li class="team-member">
                    <img src="./img/asset.png">
                    <div class="intro">
                        <span class="name">严峻</span>
                        <span class="pos">CMO</span>
                        <p class="desc">
                            昆仑万维市场VP，互联网及游戏行业多年市场营销经验，曾主导多款千万用户级互联网产品的全球营销工作，
                            精通线上线下广告运作模式，是业内少有的具有数亿美金市场费用投放实操经验的行业专家。
                        </p>
                    </div>
                </li>
                <li class="team-member">
                    <img src="./img/marketing.png">
                    <div class="intro">
                        <span class="name">贾旭东</span>
                        <span class="pos">产品合伙人</span>
                        <p class="desc">
                            前聚美优品母婴业务负责人兼产品总监，先后在京东、新浪微博任职。仅用四个月时间造就中国最大跨境母婴电商，
                            深度亲历近五年中国现象级互联网公司的黄金时代，是极少拥有运营、营销、产品综合经验的创业者。
                        </p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script type="text/babel">

    export default {
        name: 'Team'
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $ultra-book-min: 760px;

    .team {
        padding: 100px 0;

        .container {
            .title {
                h4 {
                    margin: 10px 0;
                    padding: 13px 0;
                    color: #b3b3b3;
                    font-size: 13px;
                    line-height: 24px;
                    text-align: center;
                    font-weight: 600;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                }

                h1 {
                    margin-top: 0;
                    margin-bottom: 50px;
                    color: #3b3b3b;
                    font-size: 38px;
                    line-height: 44px;
                    font-weight: 600;
                    text-align: center;
                }

                .line {
                    display: block;
                    width: 30%;
                    height: 1px;
                    margin-right: auto;
                    margin-left: auto;
                    background-color: #e1e1e1;
                }

                .circle-stroke {
                    position: relative;
                    bottom: 8px;
                    display: block;
                    width: 15px;
                    height: 15px;
                    margin-right: auto;
                    margin-left: auto;
                    border: 1px solid #d4d4d4;
                    border-radius: 100%;
                    background-color: #f2f2f2;
                }
            }

            .team-wrapper {
                display: flex;
                flex-wrap: wrap;

                .team-member {
                    display: flex;
                    margin-top: 70px;
                    padding-right: 40px;
                    width: 49.5%;

                    img {
                        margin-right: 20px;
                        width: 100px;
                        height: 100px;
                    }

                    .intro {
                        display: flex;
                        flex-direction: column;

                        .name {
                            margin-bottom: 10px;
                            font-size: 16px;
                            font-weight: 700;
                        }

                        .pos {
                            margin-bottom: 10px;
                            font-family: "Sorts Mill Goudy", sans-serif;
                            color: #7a7a7a;
                            font-size: 16px;
                            font-style: italic;
                        }

                        .desc {
                            margin-top: 0;
                            margin-bottom: 5px;
                            padding-right: 0;
                            padding-left: 0;
                            font-family: Raleway, sans-serif;
                            color: #919191;
                            font-size: 13px;
                            line-height: 26px;
                            font-weight: 400;
                        }
                    }
                }

                @media screen and (max-width: $ultra-book-min) {
                    .team-member {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding-right: 0;
                        width: 100%;

                        img {
                            margin-bottom: 20px;
                            margin-right: 0;
                        }

                        .intro {
                            align-items: center;
                        }
                    }
                }
            }
        }
    }

</style>
