<!-- @Author: hongweihu -->
<!-- @Date: 2022-06-08 16:27:21.286 -->
<!-- @Last Modified by: hongweihu -->
<!-- @Last Modified time: 2023-10-23 13:00:29 -->

<template>
    <div
        id="services"
        class="service"
    >
        <div class="container">
            <div class="title">
                <h4>WE MAKE IT EASY.</h4>
                <h1>我们的服务</h1>
                <div>
                    <div class="line" />
                    <div class="circle-stroke" />
                </div>
            </div>
            <div class="service-wrapper">
                <div class="img-wrapper">
                    <img src="./img/iphone6_lingyue.jpg">
                </div>
                <div class="column first">
                    <div class="service-detail first">
                        <div class="circle-service">
                            <img src="./img/ico1.png">
                        </div>
                        <span class="title">项目发布</span>
                        <p class="desc">
                            客户可通过{{ companyInfo.shortName }}发布自己的需求，如：问题咨询、招聘求职、物品采购等。
                        </p>
                    </div>

                    <div class="service-detail last">
                        <div class="circle-service">
                            <img src="./img/ico2.png">
                        </div>
                        <span class="title">网站设计</span>
                        <p class="desc">
                            优秀的设计团队，为您提供符合您业务需要并且美观的公司网页解决方案。
                        </p>
                    </div>
                </div>
                <div class="column">
                    <div class="service-detail first">
                        <div class="circle-service">
                            <img src="./img/ico5.png">
                        </div>
                        <span class="title">项目对接</span>
                        <p class="desc">
                            服务提供方在{{ companyInfo.shortName }}注册通过验证成为用户后可按照分类筛选有潜在意愿的需求方。
                        </p>
                    </div>

                    <div class="service-detail">
                        <div class="circle-service">
                            <img src="./img/ico4.png">
                        </div>
                        <span class="title">电商开发</span>
                        <p class="desc">
                            依托丰富的电子商务运营经验，可以提供一揽子电子商务解决方案。
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
    import {mapGetters} from 'vuex';

    import {G_TYPES} from 'moneycan/common/store/company-info';

    export default {
        name: 'Service',

        computed: {
            ...mapGetters({
                companyInfo: G_TYPES.GET_COMPANY_INFO
            })
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $ultra-book-min: 760px;

    .service {
        padding: 100px 0;

        .container {
            .title {
                h4 {
                    margin: 10px 0;
                    padding: 13px 0;
                    color: #b3b3b3;
                    font-size: 13px;
                    line-height: 24px;
                    text-align: center;
                    font-weight: 600;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                }

                h1 {
                    margin-top: 0;
                    margin-bottom: 50px;
                    color: #3b3b3b;
                    font-size: 38px;
                    line-height: 44px;
                    font-weight: 600;
                    text-align: center;
                }

                .line {
                    display: block;
                    width: 30%;
                    height: 1px;
                    margin-right: auto;
                    margin-left: auto;
                    background-color: #e1e1e1;
                }

                .circle-stroke {
                    position: relative;
                    bottom: 8px;
                    display: block;
                    width: 15px;
                    height: 15px;
                    margin-right: auto;
                    margin-left: auto;
                    border: 1px solid #d4d4d4;
                    border-radius: 100%;
                    background-color: #f2f2f2;
                }
            }

            .service-wrapper {
                display: flex;
                margin-top: 70px;

                .img-wrapper {
                    flex: 1;

                    img {
                        max-width: 276px;
                    }
                }

                .column {
                    flex: 1;

                    .service-detail {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 30px 15px;
                        background-color: #fff;

                        &.first {
                            margin-bottom: 20px;
                        }

                        .circle-service {
                            margin-bottom: 15px;
                            width: 80px;
                            height: 80px;
                            padding-top: 23px;
                            border-radius: 40px;
                            background-color: #f2f2f2;
                            text-align: center;

                            img {
                                width: 35px;
                            }
                        }

                        .title {
                            font-size: 16px;
                            font-weight: 700;
                            margin-bottom: 15px;
                        }

                        .desc {
                            margin-bottom: 5px;
                            font-family: Raleway, sans-serif;
                            color: #919191;
                            font-size: 13px;
                            line-height: 26px;
                            font-weight: 400;
                            text-align: center;
                        }
                    }

                    &.first {
                        margin-right: 20px;
                    }
                }

                @media screen and (max-width: $ultra-book-min) {
                    flex-direction: column;

                    .column {
                        &.first {
                            margin-right: 0;
                        }

                        .service-detail {
                            &.last {
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

</style>
